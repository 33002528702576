import { useEffect, useRef } from 'react'
import Button from 'src/components/ui/Button'
import { useRemoveButton } from 'src/sdk/cart/useRemoveButton'
import type { Item as ICartItem } from 'src/sdk/checkout/entity/orderForm'
import { useModal } from 'src/sdk/ui/modal/Provider'
import { Image } from 'src/components/ui/Image'
import { Link, navigate } from 'gatsby'
import { changeVtexImageSize } from 'src/utils/changeVtexImageSize'
import { useCheckout } from 'src/sdk/checkout/useCheckout'

interface Props {
  item: ICartItem
}

function CartItem({ item }: Props) {
  const { isLoading } = useCheckout()
  const { onModalClose } = useModal()

  const cartItemRef = useRef<HTMLDivElement>(null)

  const {
    seller,
    id,
    detailUrl,
    productName,
    quantity,
    sellingPrice,
    price,
    imageUrl,
    hasDiscount,
    listPrice,
  } = item

  const resizedImageUrl = changeVtexImageSize(imageUrl, '160-160')

  const btnProps = useRemoveButton(item)

  const navigateToProduct = () => {
    onModalClose()
    navigate(detailUrl)
  }

  const removeItem = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isLoading) {
      return
    }

    if (cartItemRef.current) {
      cartItemRef.current.classList.add('removed')
    }

    btnProps.onClick(event)
  }

  useEffect(() => {
    if (isLoading) {
      return
    }

    cartItemRef?.current?.classList.remove('removed')
  }, [isLoading])

  return (
    <div
      ref={cartItemRef}
      data-testid="cart-item"
      className="cart-item-summary "
      data-sku={id}
      data-seller={seller}
    >
      <div className="cart-image-summary">
        <button onClick={navigateToProduct}>
          <Image
            src={resizedImageUrl ?? ''}
            srcSet={resizedImageUrl}
            alt={productName}
            width={160}
            height={160}
            loading="lazy"
          />
        </button>
      </div>
      <div className="cart-details-summary">
        <Link className="cart-item-name" to={detailUrl} onClick={onModalClose}>
          {productName}
        </Link>
        <p className="cart-item-quantity">Qtd: {quantity}</p>
        <p className="cart-item-price">
          <div>
            <span className="selling-price">{sellingPrice ?? price} un. </span>
            {hasDiscount && <span className="list-price">{listPrice}</span>}
          </div>
        </p>
        <Button
          aria-label="remove item of cart"
          name="remover-item-do-carrinho"
          data-testid="remove-from-cart-button"
          className="remove-item-btn"
          data-sku={btnProps['data-sku']}
          onClick={removeItem}
        >
          Excluir
        </Button>
      </div>
    </div>
  )
}

export default CartItem
