import './src/styles/fonts.css'
import './src/styles/vendors/tailwind.scss'
import './src/styles/vendors/accessible-nprogress.scss'
import './src/styles/global/tokens.scss'
import './src/styles/global/typography.scss'
import './src/styles/global/layout.scss'

import { SessionProvider, UIProvider } from '@faststore/sdk'
import type { GatsbyBrowser } from 'gatsby'
import { ModalProvider } from 'src/sdk/ui/modal'
import AnalyticsHandler from 'src/sdk/analytics'
import { B2B } from 'src/store-config/B2B-Config'
import { B2C } from 'src/store-config/B2C-Config'
import { checkEnviromentIsB2B } from 'src/utils/checkEnviroment'
import { MemberProvider } from 'src/sdk/member/context/member'
import { AccountProvider } from 'src/components/account/context'
import { ToastProvider } from 'src/components/ui/ToastMessage/ToastContext'
import { ModalSheetProvider } from 'src/components/contexts/ModalSheetContext'
import initializeServiceChatBot from 'src/utils/initializeServiceChatBot'

import {
  makeVirtualPageEvent,
  sendEvent,
} from './src/utils/restructure/analytics'
import Layout from './src/Layout'
import ErrorBoundary from './src/sdk/error/ErrorBoundary'
import TestProvider from './src/sdk/tests'
import { uiActions, uiEffects, uiInitialState } from './src/sdk/ui'

interface OnRouteUpdateProps {
  location: {
    pathname: string
    search: string
    hash: string
  }
  prevLocation: {
    pathname: string
    href: string
    origin: string
  }
}

export const onRouteUpdate = ({
  location,
  prevLocation,
}: OnRouteUpdateProps) => {
  if (window) {
    const event = makeVirtualPageEvent(window, prevLocation)

    sendEvent(event)
  }

  if (document) {
    initializeServiceChatBot(location.pathname)
  }

  if (
    process.env.NODE_ENV === 'production' ||
    process.env.NODE_ENV === 'build'
  ) {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined

    setTimeout(() => {
      if (typeof gtag === 'function') {
        gtag('event', 'page_view', { page_path: pagePath })
      }
    }, 100)
  }
}

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => {
  const isB2B = checkEnviromentIsB2B()

  return (
    <ErrorBoundary>
      <AnalyticsHandler />

      <TestProvider>
        <UIProvider
          initialState={uiInitialState}
          actions={uiActions}
          effects={uiEffects}
        >
          <SessionProvider
            initialState={{
              channel: `salesChannel:${
                isB2B ? B2B.salesChannel : B2C.salesChannel
              }`,
              country: 'BRA',
              currency: { code: 'BRL', symbol: 'R$' },
            }}
          >
            <ModalSheetProvider>
              <ModalProvider>
                <ToastProvider>
                  <AccountProvider>
                    <MemberProvider>{element}</MemberProvider>
                  </AccountProvider>
                </ToastProvider>
              </ModalProvider>
            </ModalSheetProvider>
          </SessionProvider>
        </UIProvider>
      </TestProvider>
    </ErrorBoundary>
  )
}

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  return <Layout {...props}>{element}</Layout>
}
