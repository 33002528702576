import { List } from '@faststore/ui'
import { useLocation } from '@reach/router'
import { useRef } from 'react'
import Button from 'src/components/ui/Button'
import IconButton from 'src/components/ui/IconButton'
import SlideOver from 'src/components/ui/SlideOver'
import { useCheckoutButton } from 'src/sdk/cart/useCheckoutButton'
import { useUI } from 'src/sdk/ui'
import Icon from 'src/components/ui/Icon'
import { useModal } from 'src/sdk/ui/modal/Provider'
import { useCheckout } from 'src/sdk/checkout/useCheckout'

import CartItem from '../CartItem'
import EmptyCart from '../EmptyCart'
import type { SummaryTotalizers } from '../OrderSummary'
import OrderSummary from '../OrderSummary'

import './cart-sidebar.scss'

type Callback = () => unknown

type ButtonProps = {
  onClick: () => void
  disabled: boolean
  'data-testid': string
}

function CartSidebar() {
  const { isEmpty, orderForm } = useCheckout()
  const btnProps = useCheckoutButton()
  const { displayMinicart, closeMinicart } = useUI()
  const { onModalClose } = useModal()
  const dismissTransition = useRef<Callback | undefined>()
  const { pathname } = useLocation()

  const { items, totalizers, orderFormId } = orderForm

  const totalItems = items.length

  const enabledToProceedToCheckout = orderFormId

  let iframe = false

  if (pathname === '/login') {
    iframe = true
  }

  return (
    <SlideOver
      isOpen={displayMinicart}
      onDismiss={closeMinicart}
      size="full"
      direction="rightSide"
      className="cart-sidebar"
    >
      <header data-testid="cart-sidebar">
        <div className="cart-sidebar__title">
          <p className="title-display">Meu carrinho</p>
        </div>
        <IconButton
          data-testid="cart-sidebar-button-close"
          aria-label="Close Cart"
          classes="cart-sidebar-button-close"
          icon=""
          onClick={onModalClose}
        />
      </header>

      {isEmpty ? (
        <EmptyCart onDismiss={() => dismissTransition.current?.()} />
      ) : (
        <>
          <List>
            {items?.map((item) => (
              <li key={item.id}>
                <CartItem item={item} />
              </li>
            ))}
          </List>

          <footer>
            <hr className="mt-[15px] border border-[#f4f4f4]" />
            <OrderSummary
              totalizers={totalizers as SummaryTotalizers}
              numberOfItems={totalItems}
              checkoutButton={
                !iframe && (
                  <Button
                    className="order-summary-checkout"
                    variant="primary"
                    icon={
                      enabledToProceedToCheckout && (
                        <Icon name="ArrowRightCart" width={7} height={11} />
                      )
                    }
                    iconPosition="right"
                    {...(btnProps as ButtonProps)}
                  >
                    {enabledToProceedToCheckout
                      ? 'Ir para o carrinho'
                      : 'Carregando...'}
                  </Button>
                )
              }
            />
          </footer>
        </>
      )}
    </SlideOver>
  )
}

export default CartSidebar
