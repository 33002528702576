type DktControlsProps = {
  showPrevSlide: () => void
  showNextSlide: () => void
  isFirst: boolean
  isLast: boolean
  rewind: boolean
  sliderId: string
}

const DktControls = ({
  showPrevSlide,
  showNextSlide,
  rewind,
  isLast,
  isFirst,
  sliderId,
}: DktControlsProps) => {
  return (
    <div
      className="dkt-slider-controls"
      role="group"
      aria-label="Slider Navigation"
    >
      <button
        aria-controls={`dkt-slider-container-${sliderId}`}
        onClick={showPrevSlide}
        className="dkt-slider-btn dkt-slider-btn-prev"
        aria-label="Ir para slide anterior"
        disabled={isFirst && !rewind}
      >
        <span className="sr-only">Previous</span>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="#fff"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.9419 4.55806C13.186 4.80214 13.186 5.19786 12.9419 5.44194L8.38388 10L12.9419 14.5581C13.186 14.8021 13.186 15.1979 12.9419 15.4419C12.6979 15.686 12.3021 15.686 12.0581 15.4419L7.05806 10.4419C6.81398 10.1979 6.81398 9.80214 7.05806 9.55806L12.0581 4.55806C12.3021 4.31398 12.6979 4.31398 12.9419 4.55806Z"
              fill="#fff"
            />
          </svg>
        </span>
      </button>

      <button
        type="button"
        aria-label="Ir para  slide seguinte"
        aria-controls={`dkt-slider-container-${sliderId}`}
        onClick={showNextSlide}
        className="dkt-slider-btn dkt-slider-btn-next"
        disabled={isLast && !rewind}
      >
        <span className="sr-only">Next</span>
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="#fff"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.05806 4.55806C7.30214 4.31398 7.69786 4.31398 7.94194 4.55806L12.9419 9.55806C13.186 9.80214 13.186 10.1979 12.9419 10.4419L7.94194 15.4419C7.69786 15.686 7.30214 15.686 7.05806 15.4419C6.81398 15.1979 6.81398 14.8021 7.05806 14.5581L11.6161 10L7.05806 5.44194C6.81398 5.19786 6.81398 4.80214 7.05806 4.55806Z"
              fill="#fff"
            />
          </svg>
        </span>
      </button>
    </div>
  )
}

export default DktControls
