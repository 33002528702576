import type { FooterSection } from 'src/components/global/types/footer-section'
import { sendEvent, makeEventTrack } from 'src/utils/restructure/analytics'
import { object, string } from 'zod'

export function validateFooter() {
  function validateProps(props?: FooterSection) {
    try {
      const schema = object({
        title: string().optional(),
        serviceLinks: object({
          text: string(),
          link: string(),
        }).array(),
        helpLinks: object({
          text: string(),
          link: string(),
        }).array(),
        aboutUsLinks: object({
          text: string(),
          link: string(),
        }).array(),
        deliveryPolicy: object({
          text: string(),
          link: string(),
        }),
        privacyPolicy: object({
          text: string(),
          link: string(),
        }),
        copyRight: string(),
      })

      schema.parse(props)

      return true
    } catch (e) {
      return false
    }
  }

  const sendEventRedirect = (item: string) => {
    const finalEvent = makeEventTrack({
      eventAction: `${item} - footer`,
      eventPage: 'Home',
    })

    sendEvent(finalEvent)
  }

  return {
    validateProps,
    sendEventRedirect,
  }
}
