import type { ReactNode } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { DisplayToasts } from '.'

interface ToastContextProps {
  sendToast: (newToast: SendedToast) => void
  removeToast: (removedToast: Toast) => void
}

export type Toast = {
  readonly id?: string
  text: string
  subtext?: string
  image?: string
  variant: 'success' | 'error' | 'warning'
}

type SendedToast = Omit<Toast, 'id'>

export const ToastContext = createContext<ToastContextProps>(
  {} as ToastContextProps
)

const TOAST_DELAY_MS = 8000

export const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [toasts, setToasts] = useState<Toast[]>([])

  useEffect(() => {
    const timer = setTimeout(() => {
      setToasts((prevToasts) => prevToasts.slice(1))
    }, TOAST_DELAY_MS)

    return () => clearTimeout(timer)
  }, [toasts])

  const sendToast = useCallback((newToast: SendedToast) => {
    const newToastWithId = { id: crypto.randomUUID(), ...newToast }

    setToasts((prevToasts) => {
      return [...prevToasts, newToastWithId]
    })
  }, [])

  const removeToast = useCallback((removedToast: Toast) => {
    setToasts((prevToasts) =>
      prevToasts.filter((toast) => toast.id !== removedToast.id)
    )
  }, [])

  return (
    <ToastContext.Provider value={{ sendToast, removeToast }}>
      <DisplayToasts toasts={toasts} />
      {children}
    </ToastContext.Provider>
  )
}

export const useToastContext = () => useContext(ToastContext)
