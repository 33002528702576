import { useStaticQuery, graphql } from 'gatsby'

type StripeData = {
  enableSession: boolean
  stripes: Array<{
    stripeText: string
    link?: string
    icon?: string
  }>
}

type Section = {
  id: string
  name: string
  data: StripeData
}

type GetStripeData = {
  cmsGlobal: {
    id: string
    sections: Section[]
  }
}

export const StripeQuery = graphql`
  query GetStripeData {
    cmsGlobal(id: { eq: "bf66aaaa-9a1c-5283-8a71-6c7f10336839" }) {
      id
      parent {
        id
      }
      sections {
        data
        name
        id
      }
    }
  }
`

export function useStripe() {
  return useStaticQuery<GetStripeData>(StripeQuery)
}
