import React, {
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react'
import type { ReactNode } from 'react'

import UseDktSlider from './UseDktSlider'
import DktControls from './DktControls'
import DktSliderItem from './DktSliderItem'
import './slider.scss'

interface SliderProps {
  children: ReactNode
  sensitivity?: number
  autoplay?: boolean
  draggable?: boolean
  autoplayInterval?: number
  showControls?: boolean
  centerItems?: boolean
  rewind?: boolean
  slidesPerView?: number
  direction?: 'horizontal' | 'vertical'
  currentSlider?: (currentSlider: number) => void
  sliderId: string
  thumb?: boolean
  startIndex?: number
  className?: string
}

export interface DktSliderFowardRef {
  showNextSlide: () => void
  showPrevSlide: () => void
  goToSlide: (index: number) => void
}

const DktSlider = forwardRef(
  (
    {
      children,
      sensitivity = 80,
      autoplay = true,
      draggable = true,
      autoplayInterval = 4000,
      showControls = true,
      direction = 'horizontal',
      centerItems = true,
      rewind = true,
      slidesPerView = 1,
      currentSlider,
      sliderId,
      thumb = false,
      className = '',
      startIndex = 0,
    }: SliderProps,
    ref
  ) => {
    const childrenArray = React.Children.toArray(children)
    const sliderContainerRef = useRef<HTMLUListElement | null>(null)
    const {
      drag,
      setDrag,
      slideIndex,
      showNextSlide,
      goToSlide,
      showPrevSlide,
      handleMouseDown,
      handleMouseUp,
      hasDragged,
      handleMove,
      handleMouseLeave,
      scrollContainer,
    } = UseDktSlider({
      startIndex,
      childrenArray,
      slidesPerView,
      rewind,
      direction,
      sensitivity,
      draggable,
      sliderContainerRef,
    })

    useEffect(() => {
      if (!autoplay || drag.isDragging || drag.isHovered) {
        return undefined
      }

      const intervalId = setInterval(showNextSlide, autoplayInterval)

      return () => clearInterval(intervalId)
    }, [
      autoplay,
      drag.isDragging,
      drag.isHovered,
      autoplayInterval,
      showNextSlide,
    ])

    useEffect(() => {
      if (typeof currentSlider === 'function') {
        currentSlider(slideIndex)
      }
    }, [currentSlider, slideIndex])

    useImperativeHandle(ref, () => ({
      showNextSlide,
      showPrevSlide,
      goToSlide,
    }))

    return (
      <section
        aria-roledescription="carousel"
        onMouseEnter={() => setDrag((prev) => ({ ...prev, isHovered: true }))}
        onMouseLeave={() => setDrag((prev) => ({ ...prev, isHovered: false }))}
        className={`dkt-slider-section ${direction} ${className}`}
        aria-live="off"
        aria-atomic="true"
      >
        <ul
          className="dkt-slider-container"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMove}
          onTouchStart={handleMouseDown}
          onTouchEnd={handleMouseUp}
          onTouchMove={handleMove}
          onMouseLeave={handleMouseLeave}
          role="presentation"
          id={`dkt-slider-container-${sliderId}`}
          ref={sliderContainerRef}
        >
          {childrenArray.map((child, index) => (
            <DktSliderItem
              key={index}
              {...{
                centerItems,
                child,
                direction,
                index,
                thumb,
                drag,
                goToSlide,
                itemsLength: childrenArray.length,
                hasDragged,
                slideIndex,
                slidesPerView,
              }}
            />
          ))}
        </ul>

        {showControls && (
          <DktControls
            isFirst={slideIndex === 0}
            isLast={slideIndex >= childrenArray.length - slidesPerView}
            rewind={thumb || rewind}
            sliderId={sliderId}
            showNextSlide={
              thumb ? () => scrollContainer('next') : showNextSlide
            }
            showPrevSlide={
              thumb ? () => scrollContainer('prev') : showPrevSlide
            }
          />
        )}
      </section>
    )
  }
)

DktSlider.displayName = 'DktSlider'
export default React.memo(DktSlider)
