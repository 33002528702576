import React from 'react'

type DktSliderItemProps = {
  child: React.ReactNode
  drag: {
    isDragging: boolean
    isHovered: boolean
    dragThreshold: boolean
    dragOffset: number
  }
  hasDragged: React.MutableRefObject<boolean>
  centerItems: boolean
  index: number
  slideIndex: number
  slidesPerView: number
  thumb: boolean
  direction: 'horizontal' | 'vertical'
  goToSlide: (index: number) => void
  itemsLength: number
}

const DktSliderItem = ({
  drag,
  hasDragged,
  centerItems,
  index,
  slideIndex,
  slidesPerView,
  thumb,
  direction,
  goToSlide,
  child,
  itemsLength,
}: DktSliderItemProps) => {
  const getTransformValue = () => {
    if (thumb) {
      return 'none'
    }

    const offsetCalculation = `calc(${-100 * slideIndex}% + ${
      drag.dragOffset
    }px)`

    const [x, y] =
      direction === 'horizontal'
        ? [offsetCalculation, 0]
        : [0, offsetCalculation]

    return `translate3d(${x}, ${y}, 0)`
  }

  return (
    <li
      aria-roledescription="slide"
      className={`slide-item ${drag.isDragging ? 'dragging' : ''} ${
        hasDragged.current ? 'has-dragged' : ''
      } ${centerItems ? 'center' : ''}`}
      key={index}
      aria-hidden={index !== slideIndex}
      tabIndex={index === slideIndex ? 0 : -1}
      aria-label={`Slide ${index + 1} de ${itemsLength}`}
      inert={slidesPerView === 1 && index !== slideIndex ? '' : undefined}
      style={{
        flex: `0 0 ${100 / slidesPerView}%`,
        transform: getTransformValue(),
      }}
      onClick={thumb ? () => goToSlide(index) : undefined}
    >
      {child}
    </li>
  )
}

export default DktSliderItem
