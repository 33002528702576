import { maskCurrency } from 'src/utils/convertCurrency'
import { HideBalanceDotsIcon } from 'src/components/Icons/HideBalanceDots'
import { useAccountContext } from 'src/components/account/context'

type CashBackDataProps = {
  borderRadius?: string
}

const CashbackBalance = ({
  borderRadius = 'rounded-t-lg',
}: CashBackDataProps) => {
  const { cashBackBalance, isLoggedIn } = useAccountContext()

  return (
    <div
      className={`${borderRadius} px-6 py-4 w-full bg-restructure-background-action-1 font-inter text-white`}
    >
      <span className="text-xs">Cashback disponível</span>
      <div className="text-xl">
        <span className="font-inter">
          {isLoggedIn ? (
            maskCurrency(cashBackBalance ?? 0)
          ) : (
            <div className="flex gap-2 items-center">
              <span className="font-inter">R$</span>
              <HideBalanceDotsIcon />
            </div>
          )}
        </span>
      </div>
    </div>
  )
}

export default CashbackBalance
