import { CartEmpty } from 'src/components/checkout/CartEmpty'
import Button from 'src/components/ui/Button'
import { useModal } from 'src/sdk/ui/modal/Provider'

interface Props {
  /**
   * This function is called when `Start Shopping` button is clicked
   */
  onDismiss: () => void
}

function EmptyCart({ onDismiss }: Props) {
  const { onModalClose } = useModal()

  return (
    <>
      <hr className="border border-solid border-[#f4f4f4] !mt-0" />

      <div className="max-w-[270px] h-full flex items-center justify-center mx-auto">
        <CartEmpty isMiniCart />
      </div>
      <Button
        aria-label="click"
        onClick={onModalClose}
        onBlur={onDismiss}
        className="text-base text-restructure-primary font-semibold min-h-[48px] w-[328px] rounded-round border border-neutral01 font-inter mx-auto mb-6"
        variant="secondary"
      >
        Continuar comprando
      </Button>
    </>
  )
}

export default EmptyCart
