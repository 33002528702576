import { useMobile } from 'src/hooks/useMobile'
import { Link } from 'gatsby'
import DktSlider from 'src/components/restructure/common/DktSlider/DktSlider'
import { useStripe } from 'src/graphql/queries/stripe'

function ConditionalLink({
  link,
  children,
}: {
  link?: string
  children: React.ReactNode
}) {
  const isTargetBlank = link?.charAt(0) !== '/' && !link?.includes('decathlon')

  return link ? (
    <Link
      rel="noreferrer"
      target={isTargetBlank ? '_blank' : '_self'}
      to={link}
    >
      {children}
    </Link>
  ) : (
    <div>{children}</div>
  )
}

export const Stripe = () => {
  const { isMobile } = useMobile()

  const stripeData = useStripe()
  const stripeSections = stripeData?.cmsGlobal?.sections?.find(
    (section) => section.name === 'Stripe - Header [Global]'
  )

  const headerStripeData = stripeSections?.data
  const isSessionEnabled = headerStripeData?.enableSession

  if (isSessionEnabled === false) {
    return <></>
  }

  return (
    <div
      data-testid="section-stripe"
      className="flex justify-center bg-restructure-background-primary-inverted h-8 md:h-10 text-restructure-primary-inverted text-center "
    >
      {headerStripeData?.stripes?.length ? (
        <DktSlider
          showControls={false}
          direction="vertical"
          autoplayInterval={4500}
          sensitivity={10}
          sliderId="stripe-slider"
        >
          {headerStripeData?.stripes.map((stripe) => (
            <div
              key={`${stripe.stripeText}-${stripe.link}`}
              className="flex items-center justify-center "
            >
              <ConditionalLink link={stripe.link}>
                <div
                  className="flex items-center mobile-caption-regular desktop-body-regular-text3 text-center py-[8px] px-[16px] leading-none"
                  data-testid="stripe-item"
                >
                  {stripe.stripeText}
                  {stripe.icon && (
                    <img
                      alt="Icon"
                      className="ml-[3px]"
                      src={stripe.icon}
                      width={isMobile ? '16' : '24'}
                      height={isMobile ? '16' : '24'}
                    />
                  )}
                </div>
              </ConditionalLink>
            </div>
          ))}
        </DktSlider>
      ) : (
        <div
          data-testid="section-stripe"
          className="flex items-center h-[32px]"
        >
          <span
            data-testid="stripe-item"
            className="mobile-caption-regular desktop-body-regular-text3"
          >
            <ConditionalLink link={headerStripeData?.stripes[0]?.link}>
              (
              <div className="flex items-center">
                {headerStripeData?.stripes[0]?.stripeText}
                {headerStripeData?.stripes[0]?.icon && (
                  <img
                    alt="Icon"
                    className="ml-[3px]"
                    src={headerStripeData?.stripes[0]?.icon}
                    width={isMobile ? '16' : '24'}
                    height={isMobile ? '16' : '24'}
                  />
                )}
              </div>
              )
            </ConditionalLink>
          </span>
        </div>
      )}
    </div>
  )
}
