import { useCallback, useRef, useState } from 'react'
import type React from 'react'

interface UseSliderOptions {
  sensitivity: number
  draggable: boolean
  rewind: boolean
  slidesPerView: number
  startIndex: number
  childrenArray: React.ReactNode[]
  direction?: 'horizontal' | 'vertical'
  sliderContainerRef: React.RefObject<HTMLUListElement | null>
}

const UseDktSlider = ({
  startIndex,
  childrenArray,
  slidesPerView,
  rewind,
  direction,
  sensitivity,
  draggable,
  sliderContainerRef,
}: UseSliderOptions) => {
  const [slideIndex, setSlideIndex] = useState(startIndex)
  const [drag, setDrag] = useState({
    isDragging: false,
    isHovered: false,
    dragThreshold: false,
    dragOffset: 0,
  })

  const dragStart = useRef(0)
  const hasDragged = useRef(false)
  const dragEnd = useRef(0)

  const maxIndex = Math.max(0, childrenArray.length - slidesPerView)

  const showNextSlide = useCallback(() => {
    setSlideIndex((index) => {
      if (index >= maxIndex) {
        return rewind ? 0 : index
      }

      return index + slidesPerView > maxIndex ? maxIndex : index + 1
    })
  }, [maxIndex, rewind, setSlideIndex, slidesPerView])

  const showPrevSlide = useCallback(() => {
    setSlideIndex((index) => {
      if (index === 0) {
        return rewind ? maxIndex : 0
      }

      return index - 1 < 0 ? 0 : index - 1
    })
  }, [maxIndex, rewind, setSlideIndex])

  const goToSlide = useCallback(
    (index: number) => {
      setSlideIndex(index)
    },
    [setSlideIndex]
  )

  const handleMouseDown = useCallback(
    (e: React.MouseEvent | React.TouchEvent) => {
      e.preventDefault()
      setDrag((prev) => ({ ...prev, isDragging: true }))

      const pos = direction === 'horizontal' ? 'clientX' : 'clientY'

      const dragStartPosition = 'touches' in e ? e.touches[0][pos] : e[pos]

      dragStart.current = dragStartPosition
    },
    [direction, setDrag]
  )

  const handleMouseUp = useCallback(
    (e: React.MouseEvent | React.TouchEvent) => {
      setDrag((prev) => ({ ...prev, isDragging: false, dragOffset: 0 }))

      const pos = direction === 'horizontal' ? 'clientX' : 'clientY'

      const stopPosition = 'touches' in e ? e.changedTouches[0][pos] : e[pos]

      const delta = stopPosition - dragStart.current

      if (Math.abs(delta) > sensitivity) {
        const isNextMove = delta < 0
        const isPrevMove = delta > 0
        const isAtFirstSlide = slideIndex === 0
        const isAtLastSlide = slideIndex === childrenArray.length - 1

        if (
          (!rewind && isNextMove && isAtLastSlide) ||
          (!rewind && isPrevMove && isAtFirstSlide)
        ) {
          return
        }

        isNextMove ? showNextSlide() : showPrevSlide()
      }

      hasDragged.current = false
    },
    [
      setDrag,
      direction,
      dragStart,
      sensitivity,
      slideIndex,
      childrenArray.length,
      rewind,
      showNextSlide,
      showPrevSlide,
    ]
  )

  const handleMouseLeave = useCallback(() => {
    if (!drag.isDragging) {
      return
    }

    const dragDistance = Math.abs(drag.dragOffset)
    const isNextMove = drag.dragOffset < 0

    hasDragged.current = false
    setDrag((prev) => ({
      ...prev,
      isDragging: false,
      isHovered: false,
      dragOffset: 0,
    }))

    if (dragDistance > sensitivity) {
      isNextMove ? showNextSlide() : showPrevSlide()
    }
  }, [
    drag.isDragging,
    drag.dragOffset,
    hasDragged,
    setDrag,
    sensitivity,
    showNextSlide,
    showPrevSlide,
  ])

  const handleMove = useCallback(
    (e: React.MouseEvent | React.TouchEvent) => {
      e.preventDefault()
      if (!drag.isDragging || !draggable) {
        return
      }

      const prop = direction === 'horizontal' ? 'clientX' : 'clientY'

      const currentPosition = 'touches' in e ? e.touches[0][prop] : e[prop]

      const offset = currentPosition - dragStart.current

      dragEnd.current = currentPosition
      const hasExceeded = Math.abs(offset) > sensitivity

      hasDragged.current = hasExceeded

      setDrag((prev) => ({
        ...prev,
        dragOffset: offset,
        dragThreshold: hasExceeded,
      }))
    },
    [
      drag.isDragging,
      draggable,
      direction,
      dragStart,
      sensitivity,
      hasDragged,
      setDrag,
    ]
  )

  const scrollContainer = useCallback(
    (directionTo: 'next' | 'prev') => {
      if (!sliderContainerRef?.current) {
        return
      }

      const scrollAmount = sliderContainerRef.current.clientWidth * 1.6

      sliderContainerRef.current.scrollBy({
        [direction === 'vertical' ? 'top' : 'left']:
          directionTo === 'next' ? scrollAmount : -scrollAmount,
        behavior: 'smooth',
      })
    },
    [direction, sliderContainerRef]
  )

  return {
    drag,
    hasDragged,
    slideIndex,
    setDrag,
    showNextSlide,
    showPrevSlide,
    goToSlide,
    handleMouseDown,
    handleMouseUp,
    handleMove,
    handleMouseLeave,
    scrollContainer,
  }
}

export default UseDktSlider
