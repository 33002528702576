import { useStaticQuery, graphql } from 'gatsby'

type LinkData = {
  text: string
  link: string
}

export type SocialData = {
  link: string
  text: string
  image: string
}

export type FooterSection = {
  title: string
  serviceLinks: LinkData[]
  helpLinks: LinkData[]
  aboutUsLinks: LinkData[]
  deliveryPolicy: LinkData
  privacyPolicy: LinkData
  copyRight: string
  featuredLinks: LinkData[]
  socialLinks: SocialData[]
}

type GetFooterData = {
  allCmsGlobal: {
    nodes: Array<{
      id: string
      sections: Array<{ data: FooterSection; id: string | null; name: string }>
    }>
  }
}

export const FooterQuery = graphql`
  query GetFooterData {
    allCmsGlobal(
      filter: { id: { eq: "bf66aaaa-9a1c-5283-8a71-6c7f10336839" } }
    ) {
      nodes {
        id
        sections {
          name
          data
          id
        }
      }
    }
  }
`

export function useFooter() {
  const globalCmsData = useStaticQuery<GetFooterData>(FooterQuery)

  return globalCmsData?.allCmsGlobal?.nodes[0]?.sections?.filter(
    (section) => section.name === 'Footer [Global]'
  )[0]?.data
}
