import type { ReactNode } from 'react'
import { List } from '@faststore/ui'
import './order-summary.scss'

export type SummaryTotalizers = {
  discounts: string
  items: string
  shipping?: string
  total: string
}
interface OrderSummaryProps {
  totalizers: SummaryTotalizers
  numberOfItems: number
  checkoutButton?: ReactNode
}

function OrderSummary({
  totalizers,
  numberOfItems,
  checkoutButton,
}: OrderSummaryProps) {
  const { discounts, items, total, shipping } = totalizers

  return (
    <div className="order-summary-container">
      <List className="order-summary-list" data-order-summary>
        <li className="items-summary">
          <span>Itens ({numberOfItems})</span>
          <span>{items}</span>
        </li>

        {discounts && (
          <li className="discounts-summary">
            <span>Descontos</span>
            <span>{discounts}</span>
          </li>
        )}

        <li className="shipping-summary">
          <span>Frete</span>
          <span>{shipping ?? 'Calculado no checkout'}</span>
        </li>
        <li className="total-summary">
          <span>Total</span>
          <span>{total}</span>
        </li>
      </List>
      {checkoutButton}
    </div>
  )
}

export default OrderSummary
