import { useCallback } from 'react'
// import { makeRemoveFromCartEvent } from 'src/utils/restructure/analytics/makeRemoveFromCartEvent'
// import type { PropsRemoveFromCartEvent } from 'src/utils/restructure/analytics/types/removeToCartEvent'
// import { sendEvent } from 'src/utils/restructure/analytics'

// import type { CartItem } from './validate'
// import { useCart } from './useCart'
import { useCheckout } from '../checkout/useCheckout'
import type { Item } from '../checkout/entity/orderForm'

// interface Props extends CartItem {
//   category?: string
//   sellerName?: string
//   productRefId?: string
//   refId?: string
// }

export const useRemoveButton = (item: Item) => {
  // const { removeItem } = useCart()
  const { updateItem } = useCheckout()

  const removeItem = useCallback(
    async (itemIndex: number) => {
      const itemToBeUpdated = {
        index: itemIndex,
        quantity: 0,
      }

      await updateItem(itemToBeUpdated)
    },
    [updateItem]
  )

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()

      if (!item) {
        return
      }

      removeItem(item.itemIndex)
    },
    [item, removeItem]
  )

  return {
    onClick,
    'data-sku': item?.id ?? 0,
  }
}
