import type { ReactNode } from 'react'
import { useState, useEffect, createContext, useMemo } from 'react'
import { useAccountContext } from 'src/components/account/context'
import { useToastContext } from 'src/components/ui/ToastMessage/ToastContext'
import { useMobile } from 'src/hooks/useMobile'

import {
  addCartItems,
  updateCartItems,
  getOrderForm,
  addCartProfile,
  removeCartProfile,
} from '../data/checkout'
import type {
  OrderItemOnAdd,
  OrderItemOnUpdate,
  ClientProfileBody,
} from '../data/types'
import type { OrderForm, Item } from '../entity/orderForm'
import { addCartCoupon } from '../data/checkout/coupon'
import { clearMessages } from '../data/checkout/messages'
import { addCartShippingData } from '../data/checkout/shippingData'
import { addCartMarketingData } from '../data/checkout/marketingData'

interface CheckoutProviderProps {
  children: ReactNode
  paramOrderFormId?: string | null
}

interface ContextValue {
  orderForm: OrderForm
  isEmpty: boolean
  isLoading: boolean
  itemsTotal: number
  addItems: (items: OrderItemOnAdd[], orderFormId?: string) => Promise<void>
  addCoupon: (coupon: string) => Promise<OrderForm>
  addShippingData: (cep: string) => Promise<OrderForm>
  getItems: () => Item[]
  updateItem: (items: OrderItemOnUpdate) => Promise<void>
}

export const CheckoutContext = createContext<ContextValue>({} as ContextValue)

export const CheckoutProvider = ({
  children,
  paramOrderFormId,
}: CheckoutProviderProps) => {
  const { userDecathlon } = useAccountContext()
  const { sendToast } = useToastContext()
  const { isMobile } = useMobile()

  const [orderForm, setOrderForm] = useState<OrderForm>({
    orderFormId: '',
    items: [],
    messages: [],
    totalizers: {},
    clientProfileData: null,
    shippingData: {},
    loggedIn: false,
    clientPreferencesData: {},
    paymentData: {},
    marketingData: {},
    customData: null,
  })

  const [isLoading, setIsLoading] = useState<boolean>(true)

  const itemsTotal = orderForm?.items?.length ?? 0

  // Adds user info
  useEffect(() => {
    if (!orderForm || !orderForm.orderFormId) {
      return
    }

    const addClientProfile = async (clientProfile: ClientProfileBody) => {
      const updatedOrderform = await addCartProfile(
        orderForm.orderFormId,
        clientProfile
      )

      setOrderForm(updatedOrderform)
    }

    const validateCheckoutOwner = () => {
      const emailInCart = orderForm.clientProfileData?.email
      const emailLoggedIn = userDecathlon?.getUserProfile.claims.email

      const cartWithProfileAndUserNotLoggedIn = emailInCart && !emailLoggedIn
      const cartWithProfileAndLoggedInUserAreNotTheSame =
        emailLoggedIn !== emailInCart

      if (cartWithProfileAndUserNotLoggedIn) {
        removeCartProfile(orderForm.orderFormId)
      }

      if (
        cartWithProfileAndLoggedInUserAreNotTheSame &&
        userDecathlon &&
        userDecathlon?.getUserProfile
      ) {
        const cpfValue =
          userDecathlon?.getUserProfile?.additional_information?.find(
            (item) => item?.id === 'cpf'
          )?.value as string

        addClientProfile({
          document: cpfValue,
          documentType: 'cpf',
          email: userDecathlon?.getUserProfile?.claims?.email,
          firstName: userDecathlon?.getUserProfile?.claims?.given_name,
          lastName: userDecathlon?.getUserProfile?.claims?.middle_name,
          phone: userDecathlon?.getUserProfile?.claims?.phone_number,
        })
      }
    }

    validateCheckoutOwner()
  }, [orderForm, userDecathlon])

  // Handle messages and convert into toasts
  useEffect(() => {
    const handleMessages = async () => {
      if (!orderForm?.messages || !orderForm?.messages.length) {
        return
      }

      const newMessage = orderForm.messages[orderForm.messages.length - 1]

      sendToast({
        text: newMessage.text,
        variant: newMessage.status,
      })

      await clearMessages(orderForm.orderFormId)
    }

    handleMessages()
  }, [orderForm.messages, orderForm.orderFormId, sendToast])

  // Fetch initial orderform
  useEffect(() => {
    if (isMobile === undefined) {
      return
    }

    const fetchData = async () => {
      try {
        const baseOrderForm = await getOrderForm(paramOrderFormId ?? '')

        const updatedOrderform = await addCartMarketingData(
          baseOrderForm.orderFormId,
          {
            utmMedium: 'site',
            utmSource: isMobile ? 'mobile' : 'desktop',
          }
        )

        setOrderForm(updatedOrderform)
        setIsLoading(false)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [isMobile, paramOrderFormId])

  const value = useMemo((): ContextValue => {
    const isEmpty = !isLoading && orderForm.items.length === 0

    const addItems = async (items: OrderItemOnAdd[], orderFormId?: string) => {
      if (!orderForm) {
        return
      }

      setIsLoading(true)

      const updatedOrderform = await addCartItems(
        orderFormId ?? orderForm.orderFormId,
        items
      )

      setOrderForm(updatedOrderform)

      setIsLoading(false)
    }

    const getItems = () => {
      if (!orderForm) {
        return []
      }

      return orderForm.items
    }

    const updateItem = async (itemToBeUpdated: OrderItemOnUpdate) => {
      setIsLoading(true)

      const items = orderForm?.items?.map((item) => {
        if (item.itemIndex === itemToBeUpdated.index) {
          return {
            index: itemToBeUpdated.index,
            quantity: itemToBeUpdated.quantity,
          }
        }

        return {
          index: item.itemIndex,
          quantity: item.quantity,
        }
      })

      const updatedOrderform = await updateCartItems(
        orderForm.orderFormId,
        items
      )

      setOrderForm(updatedOrderform)
      setIsLoading(false)
    }

    const addCoupon = async (coupon: string) => {
      const updatedOrderform = await addCartCoupon(
        orderForm.orderFormId,
        coupon
      )

      setOrderForm(updatedOrderform)

      return updatedOrderform
    }

    const addShippingData = async (cep: string) => {
      const updatedOrderform = await addCartShippingData(
        orderForm.orderFormId,
        cep
      )

      setOrderForm(updatedOrderform)

      return updatedOrderform
    }

    return {
      orderForm,
      isEmpty,
      isLoading,
      addItems,
      getItems,
      addCoupon,
      addShippingData,
      updateItem,
      itemsTotal,
    }
  }, [isLoading, itemsTotal, orderForm])

  return (
    <CheckoutContext.Provider value={value}>
      {children}
    </CheckoutContext.Provider>
  )
}
