import { ArrowRight } from 'src/components/Icons/ArrowRight'

import { validateFooter } from './validateFooter'

type SubHeaderProps = {
  title: string
  link: string
}

export const SubHeader: React.FC<SubHeaderProps> = ({ title, link }) => {
  const { sendEventRedirect } = validateFooter()

  return (
    <a
      data-testid="footerSubHeader"
      onClick={() => sendEventRedirect(title)}
      aria-label={`Link de redirecionamento para tela ${title}`}
      href={link}
      className="group text-restructure-white
mobile-heading-title4
tablet-mobile-heading-title4
desktop-heading-title4 inline-flex items-center"
    >
      {title}
      <ArrowRight
        className="hidden group-hover:inline-flex ml-2"
        color="white"
        viewBox="0 0 26 21"
        width={32}
        height={32}
      />
    </a>
  )
}
