/**
 * More info at: https://www.notion.so/vtexhandbook/Event-API-Documentation-48eee26730cf4d7f80f8fd7262231f84
 */
// import type { AnalyticsEvent } from '@faststore/sdk'

import { getStoreConfig } from 'src/store-config/get-config'

import type {
  SearchAutocomplete,
  SearchClickEvent,
  SearchEventQuery,
  SearchSelectItemEvent,
} from '../types'

function getCookie(name: string): string | null {
  const cookies = document.cookie.split(';')

  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split('=').map((c) => c.trim())

    if (cookieName === name) {
      return cookieValue
    }
  }

  return null
}

function setCookie(name: string, value: string, secondsToExpire: number): void {
  const currentDate = new Date()
  const expirationDate = new Date(
    currentDate.getTime() + secondsToExpire * 1000
  )

  const cookieString = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`
  const cookieStringDomain = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/; domain=.decathlon.com.br`

  document.cookie = cookieString
  document.cookie = cookieStringDomain
}

export const EVENT_API_BASE_PATH = 'https://sp.vtex.com'

const THIRTY_MINUTES_S = 30 * 60
const ONE_YEAR_S = 365 * 24 * 3600

const randomUUID = () =>
  typeof crypto.randomUUID === 'function'
    ? crypto.randomUUID()
    : (Math.random() * 1e6).toFixed(0)

const createStorage = (key: string, expiresSecond: number) => {
  return () => {
    const cookieName = key

    let payload = getCookie(cookieName)

    if (!payload) {
      payload = randomUUID()
      setCookie(cookieName, payload, expiresSecond)
    }

    return payload
  }
}

const user = {
  anonymous: createStorage('vtex.search.anonymous', ONE_YEAR_S),
  session: createStorage('vtex.search.session', THIRTY_MINUTES_S),
}

type SearchEvent =
  | {
      type: 'session.ping'
    }
  | {
      position: number
      productId: string
      text: string
      url: string
      type: 'search.click'
    }
  | {
      type: 'search.query'
      text: string
      misspelled: boolean
      match: number
      operator: 'and' | 'or'
      locale: string
      session?: string
      anonymous?: string
    }
  | {
      type: 'search.autocomplete.query'
      text: string
      misspelled: boolean
      match: number
      operator: 'and' | 'or'
      locale: string
      session?: string
      anonymous?: string
    }

const sendEvent = (options: SearchEvent & { url?: string }) => {
  if (typeof window === 'undefined' || typeof document === 'undefined') {
    return
  }

  const userAgent = window?.navigator?.userAgent ?? ''

  const { accountName } = getStoreConfig()

  fetch(`https://sp.vtex.com/event-api/v1/${accountName}/event`, {
    method: 'POST',
    body: JSON.stringify({
      ...options,
      userAgent,
      anonymous: user.anonymous(),
      session: user.session(),
    }),
    headers: {
      'content-type': 'application/json',
    },
  })
    .then(() => console.warn('Intelligent Search Event Sent'))
    .catch((err) => {
      console.warn(err)
    })
}

const isFullTextSearch = (url: URL) =>
  typeof url.searchParams.get('q') === 'string' &&
  /^\/s(\/)?$/g.test(url.pathname)

export const handleSearchSelectItemEvent = (event: SearchSelectItemEvent) => {
  const url = new URL(event.params.url)

  if (event.name !== 'search_select_item') {
    return
  }

  if (!isFullTextSearch(url)) {
    return
  }

  for (const item of event.params.items ?? []) {
    const productId = item.item_id ?? item?.item_variant
    const position = item?.index

    if (productId && position) {
      sendEvent({
        type: 'search.click',
        productId,
        position,
        url: url.href,
        text: url.searchParams.get('q') ?? '<empty>',
      })
    }
  }
}

export const sendSearchClickEvent = (event: SearchClickEvent) => {
  const url = new URL(event.params.url)

  if (event.name === 'search_select_item') {
    sendEvent({
      type: 'search.click',
      productId: event.params.productId,
      position: event.params.position,
      url: url.href,
      text: url.searchParams.get('q') ?? '<empty>',
    })
  }
}

export const sendSearchQueryEvent = (event: SearchEventQuery) => {
  const url = new URL(event.params.url)

  if (event.name === 'intelligent_search_query') {
    sendEvent({
      type: 'search.query',
      url: url.href,
      text: url.searchParams.get('q') ?? '<empty>',
      misspelled: false,
      match: event.params.results,
      operator: 'and',
      locale: 'pt-BR',
    })
  }
}

export const sendAutoCompleteEvent = (event: SearchAutocomplete) => {
  const url = new URL(event.params.url)

  if (event.name === 'search_autocomplete') {
    sendEvent({
      type: 'search.autocomplete.query',
      url: url.href,
      text: event.params.text ?? '<empty>',
      misspelled: event.params.misspelled,
      match: event.params.results,
      operator: 'and',
      locale: 'pt-BR',
    })
  }
}

setInterval(
  () => sendEvent({ type: 'session.ping' }),
  60 * 1e3 /* One minute */
)
